<template>
	<div class="nueva-ventana">
		<!-- <form class="mb-4"> -->
			<div class="d-flex flex-wrap gap-4">
				<div class="d-flex flex-column gap-2 wm-250px wM-392px flex-grow-1">
					<div class="d-middle justify-content-between mb-4">
						<h2 class="f-medium f-20 text-black m-0">Ventana emergente</h2>
						<el-popover placement="bottom" trigger="hover" :visible-arrow="false">
							<div class="row align-items-center cr-pointer item mx-0 text-black px-2 br-4" style="height:25px;" @click="abrirEliminarVentana">
								Eliminar
							</div>
							<button slot="reference" type="button" class="btn-create d-middle-center border br-4 px-1">
								<i class="icon-opciones f-15"/>
							</button>
						</el-popover>
					</div>
					<div>
						<label for="nombre" class="f-12 pl-3">Nombre</label>
						<el-input id="nombre" v-model="model.nombre" size="small" placeholder="Ingrese el nombre"/>
					</div>
					<div class="d-flex gap-4">
						<div class="w-50">
							<label for="fecha-inicio" class="f-12 pl-3">Desde</label>
							<el-date-picker
							id="fecha-inicio"
							v-model="model.fechaInicio"
							class="w-100"
							type="date"
							size="small"
							format="dd/MM/yyyy"
							value-format="dd-MM-yyyy"
							range-separator="/"
							placeholder="Fecha"
							:picker-options="pickerOptionsDate"
							/>
						</div>
						<div class="w-50">
							<label for="hora-inicio" class="f-12 pl-3">Hora</label>
							<el-time-picker
							id="hora-inicio"
							v-model="model.horaInicio"
							class="w-100"
							size="small"
							format="HH:mm"
							value-format="HH:mm"
							placeholder="Hora"
							/>
						</div>
					</div>
					<div class="d-flex gap-4 mb-2">
						<div class="w-50">
							<label for="fecha-fin" class="f-12 pl-3">Hasta</label>
							<el-date-picker
							id="fecha-fin"
							v-model="model.fechaFin"
							class="w-100"
							type="date"
							size="small"
							format="dd/MM/yyyy"
							value-format="dd-MM-yyyy"
							placeholder="Fecha"
							range-separator="/"
							:picker-options="pickerOptionsDate"
							/>
						</div>
						<div class="w-50">
							<label for="hora-fin" class="f-12 pl-3">Hora</label>
							<el-time-picker
							id="hora-fin"
							v-model="model.horaFin"
							class="w-100"
							size="small"
							format="HH:mm"
							value-format="HH:mm"
							placeholder="Hora"
							/>
						</div>
					</div>
					<div class="mb-3">
						<span class="f-light text-black f-14 pr-2">{{ !model.estado ? 'Activar' : "Inactivar" }}</span>
						<el-switch
						v-model="model.estado"
						active-color="var(--color-general)"
						:active-value="1" :inactive-value="0"
						/>
					</div>
					<div>
						<h2 class="f-medium f-20 text-black">Agregar conjuntos</h2>
						<p class="f-12 text-black f-light mb-3">Selecciona los conjuntos que podrán ver esta ventana emergente</p>
						<div class="d-middle flex-wrap justify-content-between gap-2">
							<div>
								<i class="icon-inmobiliaria text-black f-15"></i>
								<span class="f-light f-14 text-black ml-2">Conjuntos seleccionados: {{ model.conjuntos }}</span>
							</div>
							<button type="button" class="border br-4 text-f5 text-86 px-2 f-14 py-1" @click="abrirAgregarConjuntos">Seleccionar conjuntos</button>
						</div>
					</div>
				</div>
				<div class="mb-4 flex-grow-1 mt-md-5 pt-md-2">
					<label for="image" class="text-center f-12 pl-3">Imagen</label>
					<div class="wm-200px wh-200px wM-300px hM-300px br-4">
						<div class="position-relative">
							<slim-cropper
							ref="logo"
							:options="slimOptions"
							class="custom-cropper cr-pointer w-100 h-100"
							/>
							<div v-if="showCropperContent" class="cropper-content d-middle-center flex-column position-absolute">
								<div class="img mb-2"/>
								<span class="text">Arrastra una imagen</span>
								<span class="text">o</span>
								<span class="text">De clic aquí</span>
							</div>
						</div>
					</div>
					<p v-if="imgErrors" class="text-danger f-10 d-block" >{{ imgErrors }}</p>
				</div>
			</div>
			<button class="btn-general px-4 mt-4" @click="editarVentana">Guardar</button>
		<!-- </form> -->
		<modal ref="modalEliminarVentana" titulo="Eliminar ventana emergente" adicional="Eliminar" no-aceptar @adicional="eliminarVentanaEmergente">
			<section class="text-center mb-3">
				<p class="f-light f-15 text-black">¿Desea eliminar la ventana emergente?</p>
			</section>
		</modal>
		<!-- <modal-agregar-conjuntos ref="modalAgregarConjuntos" /> -->
		<modal-agregar-conjuntos ref="modalAgregarConjuntos" :checkboxes="conjuntos" :idConjuntos="model.idConjuntos" @checkList="seleccionarConjuntos" />
	</div>
</template>

<script>
import modalAgregarConjuntos from './partials/modalAgregarConjuntos.vue'
import { detalleVentana, editarVentana, eliminarVentana } from '~/services/ventanas'
import Conjuntos from '~/services/conjuntos'
import moment from 'moment'
export default {
	components: {
		modalAgregarConjuntos
	},
	data(){
		return {
			pickerOptionsDate: {
                disabledDate(time){
                    return time.getTime() < moment().subtract(1,'days');
                }
            },
			conjuntos: [],
			model: {
				id: null,
				logo: '',
				nombre: '',
				fechaInicio: '',
				horaInicio: '',
				fechaFin: '',
				horaFin: '',
				estado: '',
				conjuntos: 0,
				idConjuntos: []
			},
			imgErrors: null,
			showCropperContent: true,
			slimOptions: {
                ratio: "1:1",
                label:'',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
				didLoad: (e) => {
					this.showCropperContent = false
					this.imgErrors = null;
					this.model.logo = e
					return true
				},
				didConfirm: (e) => {
					this.$nextTick(() => {
						e.output.image.toBlob(blob => {
							this.model.logo = new File([blob], 'logo.png', {type:'image/png'});
						});
					})
				},
				didRemove: (e) => {
					this.model.logo = ''
					this.showCropperContent = true
					this.$nextTick(() => {
						this.imgErrors = 'Debe seleccionar una imagen'
					})
				}
            },
		}
	},
	created(){
		this.obtenerConjuntos();
	},
	mounted(){
      if(this.$route.params.id){
        this.getVentana();
      }
    },
	methods: {
		abrirEliminarVentana(){
			this.$refs.modalEliminarVentana.toggle();
		},
		abrirAgregarConjuntos(){
			this.$refs.modalAgregarConjuntos.toggle();
		},
		seleccionarConjuntos(conjuntos){
			this.model.idConjuntos = conjuntos;
			this.model.conjuntos = conjuntos.length;
			this.$refs.modalAgregarConjuntos.toggle();
		},
		async getVentana(){
			try {
				const { data } = await detalleVentana(this.$route.params.id);

				this.model= {
                    id: data.data.id,
                    nombre: data.data.nombre,
                    estado: data.data.estado,
                    fechaInicio: moment(data.data.fechaInicio).format('DD-MM-yyyy'),
                    horaInicio: moment(data.data.fechaInicio).format('HH:mm'),
                    fechaFin: moment(data.data.fechaFin).format('DD-MM-yyyy'),
                    horaFin: moment(data.data.fechaFin).format('HH:mm'),
                    logo: data.data.imagen,
                    conjuntos: data.data.conjuntos,
					idConjuntos: data.data.idConjuntos
                };

				this.showCropperContent = true;
				this.$refs.logo.set_image(data.data.imagen);

			} catch (error) {
				this.error_catch(e)
			}
		},
		async obtenerConjuntos(){
			try {
				const { data } = await Conjuntos.listarConjuntos()
				this.conjuntos = data.data
			} catch (error){
                this.error_catch(error)
			}
		},
		async editarVentana(){
			try {
				if(this.model.logo == '' || this.model.logo == null) return this.notificacion('','Debe seleccionar una imagen','warning');
				if(this.model.idConjuntos.length === 0) return this.notificacion('','Debe seleccionar un conjunto','warning');

				const formData = new FormData();
				formData.append('nombre', this.model.nombre.trim());
				formData.append('imagen', this.model.logo)
				formData.append('fecha_inicio', `${this.model.fechaInicio} ${this.model.horaInicio}`)
				formData.append('fecha_fin', `${this.model.fechaFin} ${this.model.horaFin}`)
				formData.append('estado', this.model.estado ? 1 : 0)
				this.model.idConjuntos.forEach(el => {
					formData.append('idConjuntos[]', el)
				});

				const { data } = await editarVentana(this.model.id, formData);

				if(!data.data) return;

				this.notificacion('Actualizado','Ventana emergente','success')
				this.$router.push({ name: 'ventanas.listado' })

			} catch (error) {
                this.error_catch(error)
			}
		},
		async eliminarVentanaEmergente(){
            try {
                const { data } = await eliminarVentana(this.model.id);
				if (data.data) {
					this.notificacion('Éxito', data.mensaje, "success");
					this.$refs.modalEliminarVentana.toggle()
					this.$router.push({ name: 'ventanas.listado' })
				}
            } catch (error){
                return this.errorCatch(error)
            }
        },
	}
}
</script>

<style lang="scss" scoped>
.nueva-ventana{
	margin: 0px 75px;
	.btn-create{
		background: transparent linear-gradient(180deg, #FFFFFF 0%, #F3F5F7 100%) 0% 0% no-repeat padding-box;
	}
}

.cropper-content{
	pointer-events: none;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	.img{
		width: 97px;
		height: 97px;
		background-image: url('/img/estados-vacios/anadir_imagen.svg');
		background-size: contain;
	}
	.text{
		color: var(--text-general);
		font-size: 11px;
	}
}
</style>